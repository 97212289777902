<template>
  <g>
    <svg:style>
      .cls-10, .cls-11, .cls-12, .cls-2, .cls-3, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-2 {
        fill: url(#glass-pattern);
      }

      .cls-3 {
        fill: none;
        stroke: #3c322c;
      }

      .cls-5 {
        fill: url(#inox-gradient);
      }

      .cls-6 {
        fill: url(#inox-gradient-2);
      }

      .cls-7 {
        fill: url(#inox-gradient-3);
      }

      .cls-8 {
        fill: url(#inox-gradient-4);
      }

      .cls-9 {
        fill: url(#inox-gradient-5);
      }

      .cls-10 {
        fill: url(#inox-gradient-6);
      }

      .cls-11 {
        fill: url(#inox-gradient-7);
      }

      .cls-12 {
        fill: url(#inox-gradient-8);
      }
    </svg:style>
    <linearGradient id="glass-gradient"
                    :x1="doorLeftWidth + 35.17"
                    :y1="doorTopHeight + 279.66"
                    :x2="doorLeftWidth + 35.17"
                    :y2="doorTopHeight + 22.26"
                    gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#f4f4f4"/>
      <stop offset="0.5" stop-color="#dee8e8"/>
      <stop offset="1" stop-color="#d1cece"/>
    </linearGradient>
    <linearGradient id="handle-gradient"
                    :x1="doorLeftWidth + 11.33"
                    :y1="doorTopHeight + 150.96"
                    :x2="doorLeftWidth + 17.01"
                    :y2="doorTopHeight + 150.96"
                    gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#989a9e"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a8aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>
    <linearGradient id="inox-gradient"
                    :x1="doorLeftWidth1 + 89.38"
                    :y1="doorTopHeight1 + 101.29"
                    :x2="doorLeftWidth1 + 89.38"
                    :y2="doorTopHeight1 + 81.42"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="inox-gradient-2"
                    data-name="inox"
                    :x1="doorLeftWidth1 + 89.38"
                    :y1="doorTopHeight1 + 141.03"
                    :x2="doorLeftWidth1 + 89.38"
                    :y2="doorTopHeight1 + 121.16"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="inox-gradient-3"
                    data-name="inox"
                    :x1="doorLeftWidth1 + 89.38"
                    :y1="doorTopHeight1 + 180.76"
                    :x2="doorLeftWidth1 + 89.38"
                    :y2="doorTopHeight1 + 160.89"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="inox-gradient-4"
                    data-name="inox"
                    :x1="doorLeftWidth1 + 89.38"
                    :y1="doorTopHeight1 + 220.49"
                    :x2="doorLeftWidth1 + 89.38"
                    :y2="doorTopHeight1 + 200.63"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="inox-gradient-5"
                    data-name="inox"
                    :x1="doorLeftWidth1 + 89.38"
                    :y1="doorTopHeight1 + 94.9"
                    :x2="doorLeftWidth1 + 89.38"
                    :y2="doorTopHeight1 + 87.81"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="inox-gradient-6"
                    data-name="inox"
                    :x1="doorLeftWidth1 + 89.38"
                    :y1="doorTopHeight1 + 134.64"
                    :x2="doorLeftWidth1 + 89.38"
                    :y2="doorTopHeight1 + 127.55"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="inox-gradient-7"
                    data-name="inox"
                    :x1="doorLeftWidth1 + 89.38"
                    :y1="doorTopHeight1 + 174.38"
                    :x2="doorLeftWidth1 + 89.38"
                    :y2="doorTopHeight1 + 167.27"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="inox-gradient-8"
                    data-name="inox"
                    :x1="doorLeftWidth1 + 89.38"
                    :y1="doorTopHeight1 + 214.1"
                    :x2="doorLeftWidth1 + 89.38"
                    :y2="doorTopHeight1 + 207.01"
                    xlink:href="#handle-gradient"/>
    <filter id="inset-shadow">
      <feOffset dx="0" dy="0"/>
      <feGaussianBlur stdDeviation="1"  result="offset-blur"/>
      <feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse"/>
      <feFlood flood-color="black" flood-opacity="1" result="color"/>
      <feComposite operator="in" in="color" in2="inverse" result="shadow"/>
      <feComponentTransfer in="shadow" result="shadow">
        <feFuncA type="linear" slope="1.25"/>
      </feComponentTransfer>
      <feComposite operator="over" in="shadow" in2="SourceGraphic"/>
    </filter>
    <g id="L22">
      <rect filter="url(#inset-shadow)"
            id="glass"
            class="cls-2"
            :x="doorLeftWidth + 22.26"
            :y="doorTopHeight + 22.26"
            :width="25.82"
            :height="inoxHeight"/>

      <line class="line"
            v-if="showImpost"
            :x1="doorLeftWidth + 59.59"
            :y1="doorTopHeight + inoxHeight + inoxOffset"
            :x2="doorLeftWidth + 59.59"
            :y2="doorTopHeight + 22.26"/>

      <path v-if="showInox"
            id="inox"
            data-name="inox"
            class="cls-5"
            :d="`
            M ${doorLeftWidth1 + 79.45} , ${doorTopHeight1 + 81.42}
            v 19.87
            H ${doorLeftWidth1 + 99.32}
            V ${doorTopHeight1 + 81.42}
            H ${doorLeftWidth1 + 79.45}
            Z
            M ${doorLeftWidth1 + 97.19} , ${doorTopHeight1 + 99.16}
            H ${doorLeftWidth1 + 81.58}
            V ${doorTopHeight1 + 83.55}
            H ${doorLeftWidth1 + 97.19}
            V ${doorTopHeight1 + 99.16}
            Z
            `"/>
      <path v-if="showInox"
            id="inox-2"
            data-name="inox"
            class="cls-6"
            :d="`
            M ${doorLeftWidth1 + 79.45} , ${doorTopHeight1 + 121.16}
            V ${doorTopHeight1 + 141}
            H ${doorLeftWidth1 + 99.32}
            V ${doorTopHeight1 + 121.16}
            H ${doorLeftWidth1 + 79.45}
            Z
            M ${doorLeftWidth1 + 97.19} , ${doorTopHeight1 + 138.9}
            H ${doorLeftWidth1 + 81.58}
            V ${doorTopHeight1 + 123.29}
            H ${doorLeftWidth1 + 97.19}
            V ${doorTopHeight1 + 138.9}
            Z
            `"/>
      <path v-if="showInox"
            id="inox-3"
            data-name="inox"
            class="cls-7"
            :d="`
            M ${doorLeftWidth1 + 79.45} , ${doorTopHeight1 + 160.89}
            v 19.86
            H ${doorLeftWidth1 + 99.32}
            V ${doorTopHeight1 + 160.89}
            H ${doorLeftWidth1 + 79.45}
            Z
            m 17.74 ,17.74
            H ${doorLeftWidth1 + 81.58}
            V ${doorTopHeight1 + 163}
            H ${doorLeftWidth1 + 97.19}
            v 15.62
            Z
            `"/>
      <path v-if="showInox"
            id="inox-4"
            data-name="inox"
            class="cls-8"
            :d="`
            M ${doorLeftWidth1 + 79.45} , ${doorTopHeight1 + 200.63}
            v 19.86
            H ${doorLeftWidth1 + 99.32}
            V ${doorTopHeight1 + 200.63}
            H ${doorLeftWidth1 + 79.45}
            Z
            m 17.74 , 17.73
            H ${doorLeftWidth1 + 81.58}
            V ${doorTopHeight1 + 202.75}
            H ${doorLeftWidth1 + 97.19}
            v 15.61
            Z
            `"/>
      <polyline v-if="showInox"
                id="inox-5"
                data-name="inox"
                class="cls-9"
                :points="`
                ${doorLeftWidth1 + 92.93} ${doorTopHeight1 + 87.81}
                ${doorLeftWidth1 + 85.84} ${doorTopHeight1 + 87.81}
                ${doorLeftWidth1 + 85.84} ${doorTopHeight1 + 94.9}
                ${doorLeftWidth1 + 92.93} ${doorTopHeight1 + 94.9}
                ${doorLeftWidth1 + 92.93} ${doorTopHeight1 + 87.81}
                `"/>
      <polyline v-if="showInox"
                id="inox-6"
                data-name="inox"
                class="cls-10"
                :points="`
                ${doorLeftWidth1 + 92.93} ${doorTopHeight1 + 127.55}
                ${doorLeftWidth1 + 85.84} ${doorTopHeight1 + 127.55}
                ${doorLeftWidth1 + 85.84} ${doorTopHeight1 + 134.64}
                ${doorLeftWidth1 + 92.93} ${doorTopHeight1 + 134.64}
                ${doorLeftWidth1 + 92.93} ${doorTopHeight1 + 127.55}
                `"/>
      <polyline v-if="showInox"
                id="inox-7"
                data-name="inox"
                class="cls-11"
                :points="`
                ${doorLeftWidth1 + 92.93} ${doorTopHeight1 + 167.27}
                ${doorLeftWidth1 + 85.84} ${doorTopHeight1 + 167.27}
                ${doorLeftWidth1 + 85.84} ${doorTopHeight1 + 174.38}
                ${doorLeftWidth1 + 92.93} ${doorTopHeight1 + 174.38}
                ${doorLeftWidth1 + 92.93} ${doorTopHeight1 + 167.27}
                `"/>
      <polyline v-if="showInox"
                id="inox-8"
                data-name="inox"
                class="cls-12"
                :points="`
                ${doorLeftWidth1 + 92.93} ${doorTopHeight1 + 207.01}
                ${doorLeftWidth1 + 85.84} ${doorTopHeight1 + 207.01}
                ${doorLeftWidth1 + 85.84} ${doorTopHeight1 + 214.1}
                ${doorLeftWidth1 + 92.93} ${doorTopHeight1 + 214.1}
                ${doorLeftWidth1 + 92.93} ${doorTopHeight1 + 207.01}
                `"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showBg',
  ],
  data() {
    return {
      inoxOffset: 22.26
    }
  },
  computed: {
    showImpost() {
      return this.showBg;
    },
    inoxHeight() {
      return this.doorHeight - 2 * this.inoxOffset
    },
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 71
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 153
    },
  }
}
</script>
